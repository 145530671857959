import { alpha, darken } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<void, "selected">({
  name: "AppMenuItem",
  uniqId: "BuxwSj",
})((theme, _, classes) => {
  return {
    root: {
      padding: theme.spacing(1.25),
      minHeight: 44,
      [`&.${classes.selected}`]: {
        backgroundColor: theme.palette.common.lighterNeutral,
        "&:hover": {
          backgroundColor: darken(
            theme.palette.common.lighterNeutral,
            theme.palette.action.hoverOpacity
          ),
        },
        "&.Mui-focused": {
          backgroundColor: darken(
            theme.palette.common.lighterNeutral,
            theme.palette.action.focusOpacity
          ),
        },
        "&.Mui-focusVisible": {
          backgroundColor: darken(
            theme.palette.common.lighterNeutral,
            theme.palette.action.hoverOpacity
          ),
        },
      },
      "&.Mui-focused": {
        backgroundColor: alpha(
          theme.palette.common.neutral,
          theme.palette.action.focusOpacity
        ),
      },
      "&:hover": {
        backgroundColor: alpha(
          theme.palette.common.neutral,
          theme.palette.action.focusOpacity
        ),
      },
    },
    selected: {},
  };
});

export default useStyles;
