import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "AppSelectMenuItem",
})(() => {
  return {
    selected: {},
  };
});

export default useStyles;
