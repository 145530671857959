import { makeStyles } from "tss-react/mui";
import _get from "lodash/get";
import { appButtonClasses } from "@/components/AppButton";
import { appIconButtonClasses } from "@/components/AppIconButton";

type StylesParams = {
  bgColor?: string;
  borderColor?: string;
  hoverBorderColor?: string;
};

const useStyles = makeStyles<StylesParams, "inputLabel" | "root">({
  name: "AppSelect",
  uniqId: "LQ8mIG",
})((theme, params) => {
  const keyToBgColorMap = {
    transparent: "transparent",
    white: theme.palette.common.white,
  };

  const bgColor =
    keyToBgColorMap[params.bgColor! as keyof typeof keyToBgColorMap] ||
    (_get(theme.palette, params?.bgColor!) as string) ||
    params?.bgColor ||
    "transparent";

  const borderColor =
    _get(theme.palette, params.borderColor!) ||
    (params.borderColor as string) ||
    theme.palette.common.lighterNeutral;
  const hoverBorderColor =
    _get(theme.palette, params.hoverBorderColor!) ||
    (params.hoverBorderColor as string) ||
    theme.palette.primary.main;

  return {
    selectBgColor: {
      backgroundColor: bgColor,
    },
    placeholder: {
      color: theme.palette.common.neutral,
      opacity: 0.42,
    },
    borderRadiusCircular: {
      borderRadius: 44,
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 44,
      },
    },
    root: {
      "& .MuiInputAdornment-root": {
        fontSize: 20,
        color: theme.palette.common.darkNeutral,
      },
      "& .MuiInputAdornment-positionEnd, & .MuiInputAdornment-positionStart": {
        [`& .${appButtonClasses.root}`]: {
          height: 44,
          [`&.${appButtonClasses.textEdgeStart},&.${appButtonClasses.textEdgeEnd},&.${appButtonClasses.textEdgeX},&.${appButtonClasses.textEdgeXY}`]:
            {
              paddingLeft: theme.spacing(1.25),
              paddingRight: theme.spacing(1.25),
            },

          [`&.${appButtonClasses.textEdgeX},&.${appButtonClasses.textEdgeXY},&.${appButtonClasses.containedEdgeX},&.${appButtonClasses.containedEdgeXY},&.${appButtonClasses.containedTonalEdgeX},&.${appButtonClasses.containedTonalEdgeXY},&.${appButtonClasses.outlinedEdgeX},&.${appButtonClasses.outlinedEdgeXY}`]:
            {
              marginLeft: theme.spacing(-1.25),
              marginRight: theme.spacing(-1.25),
            },

          [`&.${appButtonClasses.textEdgeStart},&.${appButtonClasses.containedEdgeStart},&.${appButtonClasses.containedTonalEdgeStart},&.${appButtonClasses.outlinedEdgeStart}`]:
            {
              marginLeft: theme.spacing(-1.25),
            },

          [`&.${appButtonClasses.textEdgeEnd},&.${appButtonClasses.containedEdgeEnd},&.${appButtonClasses.containedTonalEdgeEnd},&.${appButtonClasses.outlinedEdgeEnd}`]:
            {
              marginRight: theme.spacing(-1.25),
            },
        },

        [`& .${appIconButtonClasses.root}`]: {
          [`&.${appIconButtonClasses.textEdgeStart},&.${appIconButtonClasses.textEdgeEnd},&.${appIconButtonClasses.textEdgeX},&.${appIconButtonClasses.textEdgeXY}`]:
            {
              padding: theme.spacing(1.25),
            },
          [`&.${appIconButtonClasses.textEdgeX},&.${appIconButtonClasses.textEdgeXY},&.${appIconButtonClasses.containedEdgeX},&.${appIconButtonClasses.containedEdgeXY},&.${appIconButtonClasses.containedTonalEdgeX},&.${appIconButtonClasses.containedTonalEdgeXY},&.${appIconButtonClasses.outlinedEdgeX},&.${appIconButtonClasses.outlinedEdgeXY}`]:
            {
              marginLeft: theme.spacing(-1.25),
              marginRight: theme.spacing(-1.25),
            },

          [`&.${appIconButtonClasses.textEdgeStart},&.${appIconButtonClasses.containedEdgeStart},&.${appIconButtonClasses.containedTonalEdgeStart},&.${appIconButtonClasses.outlinedEdgeStart}`]:
            {
              marginLeft: theme.spacing(-1.25),
            },

          [`&.${appIconButtonClasses.textEdgeEnd},&.${appIconButtonClasses.containedEdgeEnd},&.${appIconButtonClasses.containedTonalEdgeEnd},&.${appIconButtonClasses.outlinedEdgeEnd}`]:
            {
              marginRight: theme.spacing(-1.25),
            },
        },
      },

      "& .MuiSelect-select, & .MuiNativeSelect-select": {
        fontSize: theme.typography.bodyReg16.fontSize,
        fontWeight: theme.typography.bodyReg16.fontWeight,
        lineHeight: theme.typography.bodyReg16.lineHeight,
        fontFamily: theme.typography.bodyReg16.fontFamily,
        padding: theme.spacing("10.5px", 1.25),
        color: theme.palette.text.primary,
        display: "flex",
        alignItems: "center",
        "&.MuiInputBase-input": {
          paddingRight: 24 + 10 + 10 / 2 - 6,
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor,
      },
      "&.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.error.main,
      },

      [`& .MuiSelect-select option[value=""], & .MuiSelect-select option:not([value])`]:
        {
          // color: "red",
        },
      [`& .MuiSelect-select:empty`]: {
        // background: "red",
      },

      "&.MuiInputBase-adornedStart": {
        paddingLeft: theme.spacing(1.25),
      },
      "& .MuiInputBase-inputAdornedStart": {
        paddingLeft: theme.spacing(0),
      },
      "&.MuiInputBase-adornedEnd": {
        paddingRight: `calc(${theme.spacing(1.25)} + 20px + ${theme.spacing(
          1.25
        )})`,
      },
      "& .MuiInputBase-inputAdornedEnd": {
        [`&.MuiSelect-select`]: {
          paddingRight: theme.spacing(0),
        },
      },

      "& .MuiNativeSelect-icon, & .MuiSelect-icon": {
        fontSize: 24,
        color: theme.palette.common.black,
        right: `calc(${theme.spacing(1.25)} - 6px)`,
        transition: theme.transitions.create(["transform"]),
      },

      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: hoverBorderColor,
        },
        "&.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.error.main,
        },
      },
      "& .Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: hoverBorderColor,
        },
        "&.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.error.main,
        },
      },
    },

    selectMenuPaper: {
      // maxHeight: `calc(${40 * 5}px + ${theme.spacing(1.25 * 2)})`,
      maxHeight: "40dvh",
      overflow: "auto",
      boxShadow: "0px 8px 24px -4px rgba(27, 46, 94, 0.08)",
    },
    selectMenuList: {},

    inputLabel: {
      maxWidth: "100%",
      paddingLeft: theme.spacing(0.75),
      "& + .MuiOutlinedInput-root": {
        marginTop: `calc(${theme.spacing(0.5)} + 21px)`,
      },
    },
    formHelperText: {
      paddingLeft: theme.spacing(0.75),
    },
  };
});

export default useStyles;
